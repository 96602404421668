import React, { useEffect } from "react";

const Warning = () => {
  return (
    <div className="main-container">
      <h1>Please come from your provider</h1>
    </div>
  );
};

export default Warning;
