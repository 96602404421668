import React, { useState, useEffect, useRef, useCallback } from "react";
import "devextreme/dist/css/dx.light.css";
import "./PaymentGateway.css";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import TextBox from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import NumberBox from "devextreme-react/number-box";
import HashLoader from "react-spinners/HashLoader";
import { showNotification } from "../components/NotificationProvider";

import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaClock } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

const PaymentGateway = ({ readyInfo, token }) => {
  const navigate = useNavigate();
  const [paymentStage, setPaymentStage] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [focus, setFocus] = useState("");
  const [paymentFinished, setPaymentFinished] = useState(false);
  const [traderName, setTraderName] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [result, setResult] = useState(null);

  const [isAmountFixed, setIsAmountFixed] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "Transaction is in progress. Are you sure to leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (readyInfo) {
      // setNumber(readyInfo.number || "");
      // setExpiry(readyInfo.expiry || "");
      // setCvc(readyInfo.cvc || "");
      setName(`${readyInfo.name} ${readyInfo.lastName}` || "");
      // setAmount(readyInfo.amount || 0);
    }
  }, [readyInfo]);

  const handleInputFocus = (name) => {
    setFocus(name);
  };

  const onCvcChange = useCallback((value) => {
    setCvc(value);
  }, []);

  const onNumberChange = useCallback((value) => {
    setNumber(value);
  }, []);

  const onExpiryChange = useCallback((value) => {
    setExpiry(value);
  }, []);

  const onNameChange = useCallback((value) => {
    setName(value);
  }, []);

  const onAmountChange = useCallback((value) => {
    setAmount(value);
  }, []);

  const handleBlur = () => {
    if (amount < 5) {
      setAmount(5);
      showNotification("Warning", "warning", "Minimum amount is 5");
    } else if (amount > 5000) {
      setAmount(5000);
      showNotification("Warning", "warning", "Maximum amount is 5000");
    }
  };

  //FIXME:
  const [depositId, setDepositId] = useState(null);
  const depositIdRef = useRef(null);
  const [smsCode, setSmsCode] = useState(null);
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resultAmount, setResultAmount] = useState(null);
  const [declineExplanation, setDeclineExplanation] = useState(null);
  const [oldAmount, setOldAmount] = useState(null);
  const [amountChanged, setAmountChanged] = useState(false);
  const [countdown, setCountdown] = useState(300);
  const countdownRef = useRef(countdown);

  useEffect(() => {
    countdownRef.current = countdown;
    if (countdown > 0) {
      const timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      if (socket) {
        socket.close();
        console.log("Time is up, closing the WebSocket connection");
        setPaymentStage(6);
      }
    }
  }, [countdown]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    depositIdRef.current = depositId;
  }, [depositId]);

  useEffect(() => {
    const ws = new WebSocket(`wss://api.paysafegate.com/ws/payment`);
    // const ws = new WebSocket(`ws://localhost:3001/ws/payment`);

    ws.onopen = () => {
      console.log("WebSocket connection opened");
      console.log("token", token);
      ws.send(JSON.stringify({ type: "authenticate", token }));
    };

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);

        console.log("Received message:", receivedMessage);
        switch (receivedMessage.type) {
          // case "continuePayment":
          //   console.log(receivedMessage.trxInfo.status);
          //   setName(
          //     `${receivedMessage.trxInfo.name} ${receivedMessage.trxInfo.lastName}`
          //   );
          //   if (receivedMessage.trxInfo.status === "1") {
          //     setPaymentStage(2);
          //   } else if (receivedMessage.trxInfo.status === "2") {
          //     setPaymentStage(3);
          //   } else if (receivedMessage.trxInfo.status === "3") {
          //     setPaymentStage(2);
          //   }
          //   break;

          case "paymentStart":
            setUserInfo(receivedMessage.trxInfo);
            console.log("Payment started:", receivedMessage.trxInfo);
            setName(
              `${receivedMessage.trxInfo.name} ${receivedMessage.trxInfo.lastName}`
            );
            setTraderName(receivedMessage.trxInfo.traderId.traderName);
            if (receivedMessage.trxInfo.amount) {
              setAmount(receivedMessage.trxInfo.amount);
              setIsAmountFixed(true);
            }
            setPaymentStage(1);
            setCountdown(300);

            break;

          case "cardInfoProcessed":
            console.log("Card information processed:", receivedMessage);
            showNotification(
              "success",
              "success",
              "Card information processed"
            );
            setDepositId(receivedMessage.depositId);
            setTraderName(receivedMessage.traderName);

            setTransactionId(receivedMessage.transactionId);
            setCountdown(600);
            break;

          case "smsRequest":
            console.log("SMS request received:", receivedMessage);
            setPaymentStage(3);
            break;

          case "depositApproved":
            console.log("Deposit approved:", receivedMessage);
            console.log("saved deposit id", depositIdRef.current);
            if (receivedMessage.depositId === depositIdRef.current) {
              setResult("approved");
              setResultAmount(receivedMessage.amount);
              setPaymentStage(4);
              setPaymentFinished(true);

              if (receivedMessage.oldAmount !== undefined) {
                setOldAmount(receivedMessage.oldAmount);
                setAmountChanged(true);
              } else {
                setAmountChanged(false);
              }

              console.log("Payment completed successfully");
            } else {
              console.log("Payment failed");
            }
            break;

          case "depositDeclined":
            console.log("Deposit declined:", receivedMessage);
            if (receivedMessage.depositId === depositIdRef.current) {
              setResult("declined");
              setDeclineExplanation(receivedMessage.explanation);
              setPaymentStage(5);
              console.log("Payment declined by the panel");
            }
            break;

          case "timeout":
            console.log("Transaction timed out:", receivedMessage.message);
            setResult("declined");
            setPaymentStage(6); // Set to a stage indicating timeout
            if (socket) {
              socket.close(); // Close the WebSocket connection
            }
            break;

          case "error":
            console.error("Error:", receivedMessage.message);
            // Handle error, possibly reset state or show a message to the user
            break;

          default:
            console.log("Unknown message type:", receivedMessage.type);
        }
      } catch (error) {
        console.error("Failed to process WebSocket message:", error);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      if (!paymentFinished) {
        setPaymentStage(7);
      }
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  const sendCardInfo = () => {
    if (!name || name.trim() === "") {
      showNotification("error", "danger", "Name is required");
      return;
    }

    const cleanedNumber = number.replace(/\s/g, "");

    if (!cleanedNumber || cleanedNumber.length < 16) {
      showNotification(
        "error",
        "danger",
        "Card number is required and must be 16 characters"
      );
      return;
    }

    if (!expiry) {
      showNotification(
        "error",
        "danger",
        'Expiry date is required and must be in "MM/YY" format'
      );
      return;
    }

    if (expiry.length !== 4) {
      showNotification(
        "error",
        "danger",
        'Expiry date must be 4 number and in "MM/YY" format'
      );
      return;
    }
    const expiryFormatted = expiry.slice(0, 2) + "/" + expiry.slice(2);

    const month = parseInt(expiry.slice(0, 2), 10);
    const year = parseInt("20" + expiry.slice(2), 10);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    if (month < 1 || month > 12) {
      showNotification("error", "danger", "Please enter a valid month");
      return;
    }

    if (year > 2050) {
      showNotification("error", "danger", year);
      showNotification("error", "danger", "Please enter a valid year value");
      return;
    }

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      showNotification(
        "error",
        "danger",
        "Card is expired, please enter a valid card"
      );

      return;
    }

    if (!cvc || cvc.length < 3) {
      showNotification(
        "error",
        "danger",
        "CVC is required and minimum 3 characters"
      );
      return;
    }

    if (!amount) {
      showNotification("error", "danger", "Amount is required");
      return;
    }

    if (amount < 5 || amount > 5000) {
      showNotification(
        "error",
        "danger",
        "Amount must be between 5 and 5000 AZN"
      );
      return;
    }

    setLoading(true);

    if (socket && socket.readyState === WebSocket.OPEN) {
      const message = {
        type: "cardInfoEntered",
        data: {
          number,
          expiry: expiryFormatted,
          cvc,
          name,
          amount,
        },
      };
      socket.send(JSON.stringify(message));
      console.log("Card information sent:", message);

      setPaymentStage(2);
    } else {
      console.error("WebSocket connection is not open");
    }
  };

  const sendSmsCode = () => {
    if (!smsCode || smsCode.trim() === "") {
      showNotification("error", "danger", "SMS code is required");
      return;
    } else if (smsCode.length < 3) {
      showNotification("error", "danger", "SMS code is too short");
      return;
    }

    setLoading(true);

    if (socket && socket.readyState === WebSocket.OPEN) {
      const message = {
        type: "smsCodeEntered",
        data: smsCode,
        token,
      };
      socket.send(JSON.stringify(message));
      console.log("Sms code sent:", message);

      setPaymentStage(2);
    } else {
      console.error("WebSocket connection is not open");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (paymentStage === 4 ||
        paymentStage === 5 ||
        paymentStage === 6 ||
        paymentStage === 7) &&
      traderName === "Trader14"
    ) {
      setTimeout(() => {
        navigate(`/result`, {
          state: { transactionId, traderName, result }, // Parametreleri state ile geçiriyoruz
        });
        // window.location.replace(redirectUrl);
      }, 3000);
    }
  }, [paymentStage]);

  const renderPaymentForm = () => {
    if (paymentStage === 0) {
      return (
        <div className="payment-loader">
          <HashLoader
            color="#007BFF"
            cssOverride={{}}
            loading
            size={80}
            speedMultiplier={1}
          />
          <div>
            {userInfo
              ? `Welcome, ${userInfo.name} ${userInfo.lastName} ${userInfo.userId}!!!`
              : "Welcome!"}
          </div>
          <div className="loader-text-stage-0">
            Waiting for payment start...
          </div>
        </div>
      );
    } else if (paymentStage === 1) {
      return (
        <form className="cc-info-form">
          <div className="dx-field">
            <div className="dx-field-label">Ad</div>
            <div className="dx-field-value">
              <TextBox
                value={name}
                onFocusIn={() => handleInputFocus("name")}
                onValueChanged={(e) => setName(e.value)}
                readOnly={true}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">Kart Nömrəsi</div>
            <div className="dx-field-value">
              <TextBox
                mask="0000 0000 0000 0000"
                maskRules={{ X: /[0-9]/ }}
                value={number}
                valueChangeEvent="input"
                onValueChange={onNumberChange}
                onFocusIn={() => handleInputFocus("number")}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">İstifadə Tarixi</div>
            <div className="dx-field-value">
              <TextBox
                mask="00/00"
                maskRules={{ X: /[0-9]/ }}
                value={expiry}
                valueChangeEvent="input"
                onValueChange={onExpiryChange}
                onFocusIn={() => handleInputFocus("expiry")}
              />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">CVC</div>
            <div className="dx-field-value">
              <TextBox
                validationStatus="valid"
                mask={traderName === "Trader12" ? "000" : "0000"}
                maskRules={{ X: /[0-9]/ }}
                maxLength={traderName === "Trader12" ? 3 : 4}
                value={cvc}
                valueChangeEvent="input"
                onValueChange={onCvcChange}
                onFocusIn={() => handleInputFocus("cvc")}
              />
            </div>
          </div>

          <div className="dx-field">
            <div className="dx-field-label">Məbləğ</div>
            <div className="dx-field-value">
              <NumberBox
                value={amount}
                format="#,##0.00 ₼"
                showSpinButtons={true}
                valueChangeEvent="input"
                onValueChange={onAmountChange}
                onFocusIn={() => handleInputFocus("amount")}
                onFocusOut={handleBlur}
                readOnly={isAmountFixed}
              />
            </div>
          </div>
          <div className="dx-field pay-button">
            <div className="dx-field-value">
              <Button
                icon="check"
                type="success"
                text="Ödəmək"
                onClick={sendCardInfo}
              />
            </div>
          </div>
        </form>
      );
    } else if (paymentStage === 2) {
      return (
        <div className="payment-loader">
          <HashLoader
            color="#007BFF"
            cssOverride={{}}
            loading
            size={80}
            speedMultiplier={1}
          />
          <div className="loader-text-stage-1">Ödəniş emal olunur...</div>
        </div>
      );
    } else if (paymentStage === 3) {
      return (
        <form className="sms-code-form">
          <div className="dx-field sms-code-input">
            <div className="dx-field-label">SMS kodu</div>
            <div className="dx-field-value ">
              <TextBox
                mask="0000000"
                maskRules={{ 0: /[0-9]/ }}
                value={smsCode}
                valueChangeEvent="input"
                onValueChanged={(e) => setSmsCode(e.value)}
                maxLength={7}
                minLength={3}
                validationStatus="valid"
                placeholder="SMS kodunu daxil edin"
                onKeyDown={(e) => {
                  if (e.event.key === "Enter") {
                    e.event.preventDefault();
                    sendSmsCode();
                  }
                }}
              />
            </div>
          </div>
          <div className="dx-field submit-button">
            <div className="dx-field-value">
              <Button
                icon="send"
                type="success"
                text="SMS göndərin"
                onClick={sendSmsCode}
              />
            </div>
          </div>
        </form>
      );
    }
    //FIXME:
    else if (paymentStage === 4) {
      return (
        <div className="payment-result">
          <FaCheckCircle className="result-icon" />
          <div className="result-text-container">
            <h2 className="result-text">Ödəniş Uğurlu</h2>
            {amountChanged ? (
              <p className="result-subtext">
                Ödəniş məbləğiniz tranzaksiya prosesi zamanı{" "}
                <span style={{ fontWeight: "bold", color: "#D9534F" }}>
                  {oldAmount} {` AZN`}
                </span>
                -den{` `}
                <span style={{ fontWeight: "bold", color: "#5CB85C" }}>
                  {resultAmount} {` AZN`}
                </span>
                -ye yeniləndi. Tranzaksiya uğurlu alındı.
              </p>
            ) : (
              <p className="result-subtext">
                <span style={{ fontWeight: "bold" }}>
                  {resultAmount}
                  {` AZN`}
                </span>{" "}
                ödənişiniz uğurla emal edildi.
              </p>
            )}
            <p className="result-subtext">
              Siz tezliklə təyinat saytına yönləndiriləcəksiniz. Zəhmət olmasa,
              gözləyin...
            </p>
          </div>
        </div>
      );
    } else if (paymentStage === 5) {
      return (
        <div className="payment-result">
          <MdCancel className="result-icon" style={{ color: "red" }} />
          <div className="result-text-container">
            <h2 className="result-text">Ödəniş rədd edildi</h2>
            <p className="result-subtext">
              Ödənişinizi emal etmək mümkün olmadı.
              {declineExplanation && (
                <p
                  className="result-subtext"
                  style={{ fontWeight: "bold", color: "#D9534F" }}
                >
                  {`Bu səbəblə; ${declineExplanation}`}
                </p>
              )}
            </p>
            <p className="result-subtext">
              Zəhmət olmasa bir daha cəhd edin. Siz tezliklə təyinat saytına
              yönləndiriləcəksiniz.
            </p>
          </div>
        </div>
      );
    } else if (paymentStage === 6) {
      return (
        <div className="payment-result">
          <FaClock className="result-icon" style={{ color: "red" }} />
          <div className="result-text-container">
            <h2 className="result-text">Ödəniş müddəti</h2>
            <p className="result-subtext">
              Ödəniş vaxtınız bitdi. Zəhmət olmasa bir daha cəhd edin. Siz
              tezliklə təyinat saytına yönləndiriləcəksiniz.
            </p>
          </div>
        </div>
      );
    } else if (paymentStage === 7) {
      return (
        <div className="payment-result">
          <FaClock className="result-icon" style={{ color: "red" }} />
          <div className="result-text-container">
            <h2 className="result-text">Ödəniş tamamlanmadı</h2>
            <p className="result-subtext">
              Ödəniş zamanı bir problem yarandı. Siz tezliklə təyinat saytına
              yönləndiriləcəksiniz. Zəhmət olmasa bir daha cəhd edin.
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="payment-gateway">
      <div className="">
        <div className="payment-gateway-header"></div>
        <div className="payment-gateway-body">
          <div className="pg-body">
            <div className="p-header">SAFEPAY</div>
            {paymentStage >= 1 && paymentStage <= 3 && (
              <div className="p-warning">
                {paymentStage === 1 &&
                  `Lütfən, göstərilən müddət ərzində lazımi məlumatları daxil edin
                və bu ekranı bağlamayın. Əks halda əməliyyatınız ləğv ediləcək`}

                {paymentStage === 2 &&
                  `Lütfən, əməliyyatınızı tamamlamaq üçün sistem tərəfindən göndərilən SMS kodunu gözləyin. SMS qutusu göründükdən sonra kodu düzgün bir şəkildə daxil edin. Yanlış və ya natamam daxil etdiyiniz halda əməliyyat ləğv ediləcək.`}

                {paymentStage === 3 &&
                  `Lütfən, əməliyyatınızı tamamlamaq üçün sistem tərəfindən göndərilən SMS kodunu gözləyin. SMS qutusu göründükdən sonra kodu düzgün bir şəkildə daxil edin. Yanlış və ya natamam daxil etdiyiniz halda əməliyyat ləğv ediləcək.`}
              </div>
            )}
            <div className="p-countdown-container">
              {countdown > 0 && (
                <div
                  className={`countdown-timer ${
                    countdown >= 60
                      ? "countdown-timer--green"
                      : "countdown-timer--red"
                  }`}
                >
                  Qalan vaxt: {formatTime(countdown)}
                </div>
              )}
            </div>
            <div className="p-body">
              {paymentStage < 4 && (
                <Cards
                  number={number}
                  name={name}
                  expiry={expiry}
                  cvc={cvc}
                  focused={focus}
                />
              )}

              {renderPaymentForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentGateway;
