import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const confirmDeposit = createAsyncThunk(
  "payment/confirmDeposit",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post(
        "/v1/deposits/confirmDeposit",
        JSON.stringify(data),
        {
          headers,
        }
      );

      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export { confirmDeposit };
