import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:3001",
  baseURL: "https://api.paysafegate.com",
  withCredentials: true,
});

// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       window.location.href = "/";
//     }
//     return Promise.reject(error);
//   }
// );

export default instance;
