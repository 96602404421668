import React from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Deposit from "./pages/Deposit";
import Warning from "./pages/Warning";
import Result from "./pages/Result";

import config from "devextreme/core/config";
import { licenseKey } from "./assets/devextreme-license";

config({ licenseKey });

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/deposit" />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/warning" element={<Warning />} />
        <Route path="/result" element={<Result />} />
        <Route path="*" element={<Warning />} />
      </Routes>
    </Router>
  );
}

export default App;
